<template>
    <div>
        <div class="inputs-row">
            <FormInput
                v-model="startDateManual"
                type="text"
                :label="$t('startDate')"
                :placeholder="dateFormat"
                @blur="onStartDayBlur"
            />

            <FormInput
                v-model="endDateManual"
                type="text"
                :label="$t('endDate')"
                :placeholder="dateFormat"
                @blur="onEndDayBlur"
            />
        </div>

        <div class="inputs-row">
            <div>
                <span class="l-inline form-label">
                    {{ $t('startTime') }}
                </span>

                <Timepicker
                    v-model="startTime"
                    format="HH:mm"
                    lazy
                    hide-clear-button
                    @input="emitInput"
                />
            </div>

            <div>
                <span class="l-inline form-label">
                    {{ $t('endTime') }}
                </span>

                <Timepicker
                    v-model="endTime"
                    format="HH:mm"
                    lazy
                    hide-clear-button
                    @input="emitInput"
                />
            </div>
        </div>

        <Datepicker
            wrapper-class="datepicker-range-input"
            :inline="true"
            :disabled-dates="disabledDates"
            :highlighted="highlightedDates"
            :language="locale"
            @input="onDateInput($event)"
        />
    </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { en, de, it } from 'vuejs-datepicker/dist/locale'
import Timepicker from 'vue2-timepicker'

import FormInput from './FormInput'

const localDateFormat = 'DD.MM.YYYY'

export default {
    name: 'DateRangeInput',
    components: {
        Datepicker,
        Timepicker,
        FormInput,
    },
    props: {
        value: {
            type: Object,
            default: null,
            validator: v =>
                !v ||
                (v.endDate instanceof Date && v.startDate instanceof Date),
        },
    },
    data() {
        return {
            isCompleted: true,
            dateFormat: localDateFormat,
            startDate: null,
            endDate: null,
            startDateManual: '',
            endDateManual: '',
            startTime: '00:00',
            endTime: '23:59',
        }
    },
    computed: {
        locale() {
            const locales = { en, de, it }
            return locales[this.$i18n.locale] || en
        },
        disabledDates() {
            return {
                ...(!this.isCompleted &&
                    !this.endDate && { to: this.startDate }),
            }
        },
        highlightedDates() {
            return {
                ...(this.startDate && { from: this.startDate }),
                ...(this.endDate && { to: this.endDate }),
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler({ startDate, endDate }) {
                this.startDate = startDate
                this.endDate = endDate
                this.startTime = startDate.toTimeString().slice(0, 5)
                this.endTime = endDate.toTimeString().slice(0, 5)
            },
        },
        startDate: {
            immediate: true,
            handler(value) {
                this.startDateManual = moment(value).isValid()
                    ? moment(value).format(this.dateFormat)
                    : null
            },
        },
        endDate: {
            immediate: true,
            handler(value) {
                this.endDateManual = moment(value).isValid()
                    ? moment(value).format(this.dateFormat)
                    : null
            },
        },
    },
    mounted() {
        this.emitInput()
    },
    methods: {
        onStartDayBlur() {
            const date = moment(this.startDateManual, this.dateFormat)

            if (!date.isValid()) {
                this.startDateManual =
                    this.startDate &&
                    moment(this.startDate).format(this.dateFormat)
                return
            }

            this.startDate =
                this.endDate && date.isSameOrAfter(this.endDate)
                    ? this.endDate
                    : date.toDate()
            this.startDate.setHours(0, 0, 0, 0)
            this.emitInput()
        },
        onEndDayBlur() {
            const date = moment(this.endDateManual, this.dateFormat)

            if (!date.isValid()) {
                this.endDateManual =
                    this.endDate && moment(this.endDate).format(this.dateFormat)
                return
            }

            this.isCompleted = true
            this.endDate =
                this.startDate && date.isSameOrBefore(this.startDate)
                    ? this.startDate
                    : date.toDate()
            this.endDate.setHours(23, 59, 59, 999)
            this.emitInput()
        },
        onDateInput(date) {
            document.activeElement.blur()

            if (this.isCompleted) {
                this.isCompleted = false
                this.startDate = date
                this.startDate.setHours(0, 0, 0, 0)
                this.endDate = null
                return
            }

            this.isCompleted = true
            this.endDate = date
            this.endDate.setHours(23, 59, 59, 999)
            this.emitInput()
        },
        emitInput() {
            if (this.startDate && this.endDate) {
                this.$emit('input', {
                    startDate: this.startDate.setHours(
                        ...this.startTime.split(':').map(item => Number(item))
                    ),
                    endDate: this.endDate.setHours(
                        ...this.endTime.split(':').map(item => Number(item)),
                        59,
                        999
                    ),
                })
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "endTime": "End day time",
        "endDate": "End date",
        "startTime": "Start day time",
        "startDate": "Start date"
    },
    "de": {
        "endTime": "Endzeit",
        "endDate": "Enddatum",
        "startTime": "Startzeit",
        "startDate": "Startdatum"
    },
    "it": {
        "endTime": "Ora di fine",
        "endDate": "Data di fine",
        "startTime": "Ora di inizio",
        "startDate": "Data di inizio"
    }
}
</i18n>

<style lang="scss" scoped>
.inputs-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    & > * {
        width: 100%;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}
</style>

<style lang="scss">
.datepicker-range-input {
    .vdp-datepicker__calendar {
        margin: 0;
        padding: 0;
        width: 100%;
        border: none;
        box-shadow: none;
        user-select: none;

        .cell {
            &.day {
                border-radius: 0;
            }
        }
    }
}
</style>
